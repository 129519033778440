
/*//Base rules
@import "scss/base/_vars.scss";
@import "scss/base/_mixins.scss";
@import "scss/base/_base.scss";
//Layout rules
@import "scss/layout/_typography.scss";
@import "scss/layout/_color.scss";
@import "scss/layout/_align.scss";
@import "scss/layout/_grid.scss";
@import "scss/layout/_background.scss";
@import "scss/layout/_buttons.scss";
@import "scss/layout/_forms.scss";

//Module rules
@import "scss/module/_heading.scss";
@import "scss/module/_header.scss";
@import "scss/module/_menu.scss";
@import "scss/module/_menu-max991.scss";
@import "scss/module/_menu-max1200.scss";
@import "scss/module/_hero.scss";
@import "scss/module/_testimonial.scss";
@import "scss/module/_feature.scss";
@import "scss/module/_action.scss";
@import "scss/module/_pricing.scss";
@import "scss/module/_footer.scss";
@import "scss/module/_clients.scss";
*/

/* Global Styles */
:root {
	--primary-color: #8139AF;
	--dark-color: #141414;
	--light-color: #f4f4f4;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
	background: #FFF;
	color: #999;
}

ul {
	list-style: none;
}

h1,
h2,
h3,
h4 {
	color: #fff;
}

a {
	color: #fff;
	text-decoration: none;
}

a:hover {
	color: #fff;
	text-decoration: underline;
}

p {
	margin: 0.5rem 0;
}

img {
	width: 100%;
}

.showcase {
	width: 100%;
	height: 65vh;
	min-height: 500px;
	position: relative;
	background: url('static/cover.jpg') no-repeat center center/contain;
}

.showcase::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(255,255,255,1);
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255, 0.75) 64%, rgba(255,255,255,0.75) 65%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(64%, rgba(255,255,255,0.75)), color-stop(65%, rgba(255,255,255,0.75)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 64%, rgba(255,255,255,0.75) 65%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 64%, rgba(255,255,255,0.75) 65%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 64%, rgba(255,255,255,0.75) 65%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 64%, rgba(255,255,255,0.75) 65%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.showcase-top {
	position: fixed;
    z-index: 3;
    height: 90px;
    top: 0;
    left: 0;
    width: 100%;
	background: #FFD500;
	.bloco {
		max-width: 1200px;
		position: relative;
		margin: 0 auto;
		img {
			margin-top: 15px;
			width: auto;
			@media(max-width: 700px) {
				margin-left: 1.5rem;
				max-width: 150px;
			}
		}
		a {
			position: absolute;
			top: 45px;
			right: 0;
			transform: translate(-50%, -50%);
		}
	}
}

.showcase-content {
	position: relative;
	z-index: 2;
	width: 75%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 2rem;
	margin-top: 90px;
}

.showcase-content h1 {
	font-weight: 700;
	font-size: 2.2rem;
	line-height: 1.1;
	margin: 0 0 2rem;
	color:#8139AF;
}

.showcase-content p {
	color: #000;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25;
	margin: 0 0 2rem;
	width: 80%;
	a {
		color:#000;
	}
}

.ctas {
	padding-top: 2rem;
	.btn-xl {
		padding: 1.5rem 4.2rem;
		border: solid 3px #8139AF;
		border-radius: 7px;
		background: #8139AF;
		color: #FFD500;
		&.link {
			padding: 1.6rem 1.2rem;
			color: #000;
			border: solid 3px #8139AF;
			border-radius: 7px;
			background: transparent;
			@media(max-width: 700px) {
				margin-bottom: 3rem;
			}
		}
	}
	
}

.barra{
	height:8px;
	width: 100%;
	background: #222;
	margin-bottom: 50px;
}
.inscrever{
	text-align: center;
	margin: 70px auto;
	max-width: 100%;
}

.blocos {
	color:#000;
	background: #FFFAE3;
	padding: 4rem 0;
	.bloco {
		background: #FFD500;
		border-radius: 20px;
		text-align: center;
		padding: 2rem 1rem 1rem 1rem;
		h2 {
			font-size: 2.5rem;
			font-weight: bolder;
			color:#000;
		}
		p {
			font-size: 1.2rem;
		}
		.de-por {
			width: 100%;
			margin-top: 20px;
			text-align: center;
			font-size: 30px;
			span{
				text-decoration: line-through;
			}
		}
		.preco {
			font-size: 1.2rem;
			margin: 1.5rem auto;
			width: auto;
			text-align: center;
			display: inline-block;
			div {
				float: left;
			}
			.valor {
				font-size: 5rem;
				font-weight: bolder;
				line-height: 4rem;
				margin: 0 .5rem;
			}
			.total {
				text-align: left;
				line-height: 1rem;
				padding-top: 1rem;
				span {
					font-size: 2rem;
					font-weight: bolder;
				}
			}
		}
		ul {
			list-style-image: url('static/list-preco.png');
			text-align: left;
			font-size: 1.3rem;
			line-height: 1rem;
			margin-left: 3rem;
			margin-bottom: 4rem;
		}
		a {
			display: inline-block;
			width: 100%;
			background: var(--primary-color);
			color: #FFF;
			text-align: center;
			text-transform: uppercase;
			padding: .5rem 0;
			font-size: 1rem;
			line-height: 2.5rem;
			font-weight: bold;
		}
	}

}
.chamadas {
	.row{
		margin: 50px auto;
	}
	.texto{
		color:#000;
		h2{
			font-size: 2rem;
			line-height: 1;
			font-weight: bold;
			margin-bottom: 1rem;
			color: var(--primary-color);
		}
		p {
			line-height: 1.5rem;
			font-size: 1.2rem;
    		font-weight: 400;
		}
		&.t1 {
			margin-top: 8rem;
			@media(max-width: 700px) {
				margin-top: 1rem;
			}
		}
		&.t2 {
			margin-top: 2rem;
			@media(max-width: 700px) {
				margin-top: 1rem;
			}
		}
		&.t3 {
			margin-top: 10rem;
			@media(max-width: 700px) {
				margin-top: 1rem;
			}
		}
	}
	img {
		max-height: 400px;
		width: auto;
		max-width: 100%;
	}
	.certificados{
		img {
			max-height: 300px;
		}
		.texto{
			padding-top: 70px;
		}
	}
}
.b2b {	
	text-align: center;
	background: url('static/bg-b2b.png') no-repeat center center/cover;
	.texto{
		color:#000;
		h2{
			font-size: 2rem;
			line-height: 1;
			font-weight: bold;
			margin-bottom: 1rem;
			color: var(--primary-color);
		}
		p {
			line-height: 1.5rem;
			font-size: 1.2rem;
    		font-weight: 400;
		}
	}
	img {
		max-height: 300px;
		width: auto;
		max-width: 90%;
		margin: 3rem auto;
		display: block;
	}
	a {
		margin: 0 auto;
		display: inline-block;
		text-decoration: none;
		text-transform: none;
		strong {
			//text-decoration: underline;
		}
	}
}
.faq{
	h2{
		font-size: 3.125rem;
		line-height: 1.1;
		margin-bottom: .5rem;
		font-weight: bolder;
		color: var(--primary-color);
		width: 100%;
		text-align: center;
		padding: 50px 0;
	}
	.card{
		background: #303030;
		margin-bottom: .4rem;
		width: 100%;
		color: #FFF;
		h5{
			i{
				float: right;
				font-size: 1.6rem;
				color: #FDD731;
			}
		}
	}
}

.footer {
	margin: 0 auto;
	padding: 5rem 0 2rem 0;
	overflow: auto;
	background: #FDD731;
	h3{
		color: var(--primary-color) !important;
		font-weight: bold;
	}
}

.footer,
.footer a {
	color: #000;
	font-size: 0.9rem;
}

.footer p {
	margin-bottom: 1.5rem;
}

// .footer .footer-cols {
// 	display: grid;
// 	grid-template-columns: repeat(2, 1fr);
// 	grid-gap: 2rem;
// }

.footer .redes{
	h3{
		margin: 1rem auto;
	}
	li{
		float: left;
		width: 20%;
		margin-left: 2%;
		&:first-of-type{
			margin-left: 0;
		}
		img {
			max-width: 50px;
		}
	}
}

.footer li {
	line-height: 1.9;
}

.footer .lang-select {
	margin-top: 2rem;
	color: #999;
	background-color: #000;
	background-image: none;
	border: 1px solid #333;
	padding: 1rem 1.2rem;
	border-radius: 5px;
}

.footer .logo {
	width: 100%;
	margin: 4rem auto 0 auto;
	text-align: center;
	img{
		margin: 0 auto;
		max-height: 70px;
		width: auto;
	}
}

/* Container */
.container {
	margin: auto;
	overflow: hidden;
	padding: 0 2rem;
}

/* Text Styles */
.text-xl {
	font-size: 2rem;
}

.text-lg {
	font-size: 1.8rem;
	margin-bottom: 1rem;
}

.text-md {
	margin-bottom: 1.5rem;
	font-size: 1.2rem;
}

.text-center {
	text-align: center;
}

.text-dark {
	color: #999;
}

/* Buttons */
.btn {
	display: inline-block;
	background: var(--primary-color);
	color: #fff;
	padding: 0.4rem 1.3rem;
	font-size: 1rem;
	text-align: center;
	border: none;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: opacity 0.2s ease-in;
	outline: none;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);
	border-radius: 2px;
}

.btn:hover {
	opacity: 0.9;
}

.btn-rounded {
	border-radius: 5px;
}

.btn-xl {
	font-size: 1.3rem;
	padding: 1.2rem 1.7rem;
	text-transform: uppercase;
}

.btn-lg {
	font-size: 1rem;
	padding: 0.8rem 1.3rem;
	text-transform: uppercase;
}

.btn-icon {
	margin-left: 1rem;
}

@media (max-width: 960px) {

		.showcase {
		height: auto;
		padding-bottom: 2rem;
	}

	.hide-sm {
		display: none;
	}

	.showcase-content  {		
		padding-top: 3rem;
	}

	.showcase-top  {
		height:110px;
	}

	.showcase-top img {
		top: 5%;
		left: 5%;
		transform: translate(0);
	}

	.showcase-top a {
		top: 50%;
		right: -10%;
	}

	.showcase-content h1 {
		font-size: 3.7rem;
		line-height: 1;
	}

	.showcase-content p {
		font-size: 1.5rem;
	}

	.footer .footer-cols {
		grid-template-columns: repeat(1, 1fr);
	}
	.btn-xl {
		font-size: 1.5rem;
		padding: 1.4rem 2rem;
		text-transform: uppercase;
	}

	.text-xl {
		font-size: 1.5rem;
	}

	.text-lg {
		font-size: 1.3rem;
		margin-bottom: 1rem;
	}

	.text-md {
		margin-bottom: 1rem;
		font-size: 1.2rem;
	}
}

@media (max-width: 700px) {

	.showcase-content h1 {
		font-size: 2rem;
		line-height: 1;
	}

	.showcase-content p {
		font-size: 1rem;
	}



	.btn-xl {
		font-size: 1rem;
		padding: 1.2rem 1.6rem;
		text-transform: uppercase;
	}
	.chamadas .texto h2 {
		font-size: 1.5rem;
	}
	.chamadas .texto p {
		font-size: 1rem;
	}

	.footer {
		padding: 2rem 0;
	}

	.footer .offset-md-1 {
		margin-top: 2rem;
	}
}

@media(max-height: 600px) {
  .showcase-content {
	margin-top: 3rem;
}
}


